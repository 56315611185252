<template>
    <div class="main">
        <div class="header">
            <div class="money">
                <span class="currency">Rp</span>{{ amount }}
            </div>
            <p class="desc">OrderlD:{{ orderId }}</p>
        </div>
        <div class="content">
            
            <p class="payUsing">Bank Transfer</p>
            <div class="bank-content">
                
                <div class="bank-item" v-for="(item, key ) in bankList " v-bind:key="key" @click="">
                    <img src="../../../assets/images/upi.jpg" />
                </div>
            </div>
        </div>
        <div class="footer">
            <div class="footLogo">
                <p>Powered by pay.aio-pay.com ver:{{ $config.software_version }}</p>

                <!-- <img src="../../assets/images/upi.jpg"> -->
            </div>

            <hr />

            <div class="paragraph">
                <p>Tips:</p>
                <p>lf your payment is successful and your account has not increased the amountplease prowide your order
                    number
                    and
                    payment voucher to contact us assoon as possilble.</p>
            </div>
            <div class="mail">
                <i class="envelope"></i>
                {{ $config.SYS_AIOPAY_EMAIL }}
            </div>

            <div class="make" @click="submitPayment" v-if="mateType === 'default'">
                <button :disabled="submitState.buttonDisabled">Make Payment</button>
            </div>
            <div class="make" @click="copyTransactionId(resData.channelOrderNo)" v-if="mateType === 'virtual'">
                <button>Salin</button>
            </div>

        </div>
    </div>
</template>
<script>
import {
    getPayOrderInfoByPayOrderId
} from '@/api/api'

export default {
    data() {
        return {
            amount: "0",
            orderId: '',
            software_version: "",
            bankList: [
                {
                    code: "BRI",
                    name: "bri",
                    img: "@/assets/images/upi.jpg"
                },
                {
                    code: "permata",
                    name: "permata",
                    img: "@/assets/images/upi.jpg"
                },
                {
                    code: "BNI",
                    name: "BNI",
                    img: "@/assets/images/upi.jpg"
                },
                {
                    code: "CIMB",
                    name: "CIMB",
                    img: "@/assets/images/upi.jpg"
                }
            ]
        }
    },
    created() {
        this.orderId = this.$route.query.tn;
        this.software_version = process.env.VUE_APP_VERSION;
    },
    mounted() { 
        const _this = this

        _this.getPaymentOrderData();
    },
    methods: {
        getPaymentOrderData() {
            const _this = this;
            getPayOrderInfoByPayOrderId(_this.orderId).then(res => { 
                console.log(`output->res`, res)
                // if (parseInt(res.code) !== 0) { 
                //     alert('res.code != 0 ');
                //     return;
                // }
                this.amount = _this.$tools.formatNumber(res.amt);
                this.payOrderInfo.am = this.amount
            })
        }
    }

}
</script>
<style>
html,
body {
    padding: 0 !important;
    margin: 0 !important;
    background-color: #eee;
}
</style>
<style scoped>
* {
    font-family: '微软雅黑';

}

.main {
    min-height: 100vh;
}

.header {
    /* Safari 5.1 - 6.0 */
    background: -webkit-linear-gradient(to right, #367af7, #76a6ff);
    /* Opera 11.1 - 12.0 */
    background: -o-linear-gradient(to right, #367af7, #76a6ff);
    /* Firefox 3.6 - 15 */
    background: -moz-linear-gradient(to right, #367af7, #76a6ff);
    background: linear-gradient(to right, #367af7, #76a6ff);
    background-color: #367af7;
    height: 4rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.header .content {
    margin-top: .4rem;
    padding: 0 .4rem;
}

.header .money {
    color: #fff;
    font-size: 102px;
    padding-left: .4rem;
    font-family: '黑体';
    font-weight: 600;
    margin-top: .6rem;
}

.header .money .currency {
    font-size: 62px;
}

.header .desc {
    font-size: 24px;
    color: #d9e4ff;
    padding-left: 0.4rem;
    margin: 0.8rem 0 0 0;
}

.content {
    margin-top: .4rem;
    padding: 0 .4rem;
}


.content>p.payUsing {
    padding: 0;
    margin: 0;
    font-size: .4rem;
    text-align: left;
    color: #939393;
}

.bank-content {
    width: 100%;
    margin: 0.4rem auto 0 auto;
    background: #fff;
    border-radius: 0.186667rem;
    padding: 0.4rem 1rem;
    box-sizing: border-box;
}

.bank-content .bank-item {
    margin-top: 0.6rem;
}

.bank-content .bank-item img {
    width: 100%;


}

.footer {
    width: 100%;
    font-size: .4rem;
    margin-top: 2rem;
    padding-bottom: 200px;
}

.footer .footLogo {
    text-align: center;
}

.footer .footLogo p {
    margin: 0;
    color: #5d5d5d;
    font-size: .2rem;

}

.footer .footLogo img {
    width: 1.0rem;
    height: auto;
    margin-top: .1rem;
}

.footer .make {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translatex(-50%);
    width: 100%;
    background-color: #eee;
}

.footer .make>button {
    /* Safari 5.1 - 6.0 */
    background: -webkit-linear-gradient(to right, #367af7, #76a6ff);
    /* Opera 11.1 - 12.0 */
    background: -o-linear-gradient(to right, #367af7, #76a6ff);
    /* Firefox 3.6 - 15 */
    background: -moz-linear-gradient(to right, #367af7, #76a6ff);
    background: linear-gradient(to right, #367af7, #76a6ff);
    background-color: #367af7;
    color: #fff;
    font-size: .6rem;
    border: none;
    border-radius: .6rem;
    display: block;
    width: 90%;
    height: 1.4rem;
    margin: .6rem auto;
}

.footer .paragraph {
    text-align: left;
    padding: 0 .6rem;
    font-size: .2rem;
    color: #939393;
}

.footer .mail {
    text-align: left;
    padding: 0 0.6rem;
    font-size: .2rem;
}

.footer hr {
    margin: .2rem .6rem;
    border: 1px solid #fff;
}

.footer .envelope::after {
    font-family: fontAwesome;
    content: "\f0e0";
    font-weight: normal;
    font-style: normal;
    font-size: .4rem;
}
</style>